<template>
  <el-carousel indicator-position="none" :height=lunboheight>
    <el-carousel-item>
      <h3 text="2xl" justify="center"><img src="../img/lunbo1.jpg" alt=""></h3>
    </el-carousel-item>
    <el-carousel-item>
      <h3 text="2xl" justify="center"><img src="../img/lunbo2.jpg" alt=""></h3>
    </el-carousel-item>
    <el-carousel-item>
      <h3 text="2xl" justify="center"><img src="../img/lunbo3.jpg" alt=""></h3>
    </el-carousel-item>
  </el-carousel>
  <div class="box1">
    <div class="center">
      <div class="left slide-in-fwd-left">
        <p>公司简介</p>
        <div>
          浙江云端时代科技有限公司成立于2014年。是一家集电子商务基础性研究、ERP系统集成、网络销售、供应链管理等多元化业务于一体的外贸B2C电子商务企业。公司致力于打造跨境电商一站式服务平台，是跨境电子商务行业中拔萃的新生力量。从国内到国外，公司经历着高速而稳健的发展。
        </div>
      </div>
      <div class="right">
        <img class="slide-in-fwd-tr" src="../img/box1.jpg" alt="">
      </div>
    </div>
  </div>
  <div class="box2">
    <div class="right">

      <p>关于云端</p>
      <div>
        公司主营服装及家居两大品类，产品远销全球100多个国家和地区。公司秉承着客户第一的理念，以诚信正直为立足点，在全体云端人的努力下，在短短的几年内业务不断扩展，从不足千万销售额逐步成长为一家拥有数亿年销售额的中大型跨境电子商务公司。
      </div>
      <img src="../img/box2.jpg" alt="">
    </div>
  </div>
  <div class="box3">
    <div class="center">
      <div class="left">
        <p>加入我们</p>
        <div>
          公司坚持以人为本的用人理念，鼓励员工在充满激励、公平竞争以及相互合作的前提下发挥最高的工作水平，并奉行能者上、庸者下、平者让的晋升机制，为员工提供既有导向性又有创新自由的工作环境、完善的培训机制、多样的学习机会、完善的福利待遇等。
        </div>
      </div>
      <div class="right">
        <img src="../img/box3.jpg" alt="">
      </div>
    </div>
  </div>
</template>
<script setup >


import { ref, triggerRef } from "vue"
// 根据屏幕的大小修改box3轮播图的大小
let lunboheight = ref("600px")
screenwid()
function screenwid() {
  if (document.querySelector("html").offsetWidth <= 960) {
    lunboheight.value = "156px"
    triggerRef(lunboheight)
  }
}










</script>
<style lang="scss"   scoped>
//box1的图片动画效果


.slide-in-fwd-tr {
  -webkit-animation: slide-in-fwd-tr 1.5s;
  animation: slide-in-fwd-tr 1.5s;
}

@-webkit-keyframes slide-in-fwd-tr {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
    transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
    transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-fwd-tr {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
    transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
    transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}

// box1文字的动画效果

.slide-in-fwd-left {
  -webkit-animation: slide-in-fwd-left 1.5s;
  animation: slide-in-fwd-left 1.5s;
}

@-webkit-keyframes slide-in-fwd-left {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(-1000px);
    transform: translateZ(-1400px) translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-fwd-left {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(-1000px);
    transform: translateZ(-1400px) translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

@media screen and (min-width: 960px) {
  .el-carousel {
    img {
      width: 100%;
      height: 100%;
    }
  }

  
  .box1,
  .box3 {
    overflow: hidden;
    // height: 770px;

    .center {
      // height: 464px;
      padding: 140px 0px;
      width: 1400px;
      margin: auto;
      display: flex;

      .left {
        margin-right: 160px;
        width: 646px;

        p {
          font-size: 28px;
          padding-bottom: 30px;
          border-bottom: 2px solid #244563;
          text-align: left;
          margin-bottom: 22px;
          color: #244563;
          font-weight: 700;
          letter-spacing: 3px;
        }

        div {
          font-size: 20px;
          line-height: 50px;
          text-align: left;
          text-indent: 36px;
          letter-spacing: 2px;
          color: #244563;
          font-weight: 500;
        }
      }

      .right {
        img {
          width: 700px;
          height: auto;
        }
      }
    }
  }

  .box2 {
    height: 772px;
    background-image: url(../img/box2.jpg);
    position: relative;

    .right {
      position: absolute;
      width: 640px;
      right: 0;
      margin: 140px 252px;

      p {
        font-size: 28px;
        color: #fff;
        text-align: right;
        padding-bottom: 40px;
        border-bottom: 2px solid #fff;
        margin-bottom: 10px;
        font-weight: 700;
        letter-spacing: 3px;

      }

      div {
        color: #fff;
        text-align: left;
        text-indent: 36px;
        font-size: 20px;
        line-height: 60px;
        letter-spacing: 2px;
      }

      img {
        display: none;
      }
    }
  }






}

@media screen and (max-width: 960px) {

  .el-carousel {

    img {
      width: 100%;
      height: 100%;
    }
  }

  .box1,
  .box3 {
    margin: 20px 40px 0;

    .left {
      p {
        font-size: 18px;
        font-weight: 700;
        padding-bottom: 16px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
      }

      div {
        font-size: 14px;
        line-height: 28px;
        text-indent: 28px;
        color: #666666;

      }
    }

    .right {
      // height: 170px;
      overflow: hidden;

      img {
        width: 100%;
        margin-top: 10px;
        border-radius: 5px;
      }
    }
  }

  .box2 {
    // height: 380px;
    margin: 10px 40px 0;
    overflow: hidden;

    .right {

      p {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 700;
        padding-bottom: 16px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
      }

      div {
        font-size: 14px;
        line-height: 28px;
        text-indent: 28px;
        color: #666666;

      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        margin-top: 10px;
        border-radius: 5px;
      }
    }
  }



}

.el-carousel--horizontal {
  z-index: -1;
}

.el-carousel__item h3 {
  display: flex;
  color: #475669;
  opacity: 0.75;
  line-height: 800px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}</style>