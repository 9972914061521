import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import index from '../views/index.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: function () {
      return import('../views/introduction.vue')
    }
  },
  {
    path: '/conscientious',
    name: 'conscientious',
    component: function () {
      return import('../views/conscientious.vue')
    }
  },
  {
    path: '/honor',
    name: 'honor',
    component: function () {
      return import('../views/honor.vue')
    }
  },
  {
    path: '/culture',
    name: 'culture',
    component: function () {
      return import('../views/culture.vue')
    }
  },
  {
    path: '/join',
    name: 'join',
    component: function () {
      return import('../views/join.vue')
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
