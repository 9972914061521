<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <div class="top">
    <div class="left">
      <div id="logo" style="display: none; width: 60px;height: 60px;margin-right: 6px;"></div>
      <div>
        <h1 class="logo">浙江云端时代科技有限公司</h1>
        <p>ZHEJIANG YUNDUAN ERA OF TECHNOLOGY CO,.LTD</p>
        <div id="right" @click="test"><img id="imgcli" src="./img/caidan.png" alt=""></div>
        <div id="right" class="right1" @click="test1" style="display: none;"><img src="./img/guanbi.png" alt=""></div>
      </div>
    </div>

    <ul class="tiaozhuan">
      <li class="li1"> <router-link to="/" @click="ondianji($event)">首页</router-link></li>
      <li class="li1"> <router-link to="/introduction" @click="ondianji($event)">公司简介</router-link></li>
      <li class="li1"> <router-link to="/conscientious" @click="ondianji($event)">主营业务</router-link></li>
      <li class="li1"> <router-link to="/honor" @click="ondianji($event)">公司荣誉</router-link></li>
      <!-- <li> <router-link to="/culture">企业文化</router-link></li> -->
      <li class="li1"> <router-link to="/join" @click="ondianji($event)">加入我们</router-link></li>
    </ul>

  </div>
  <router-view />
  <div class="bottom">
    <div class="center">
      <div class="top">
        <div class="lef">
          <div class="one">
            <p class="logo">浙江云端时代科技有限公司</p>
            <p>ZHEJIANG YUNDUAN ERA OF TECHNOLOGY CO,.LTD</p>
          </div>
          <div class="two">关注我们，了解更多咨询和动态</div>
          <div class="three">
            <img class="four" src="./img/jion/erweima.jpg" alt="">
            <img class="three1" src="./img/weixin3.png" alt="">


          </div>
        </div>
        <ul class="rig">
          <li>
            <p>
            </p>
            <!-- <p>社会招聘</p> -->
          </li>

        </ul>

      </div>
      <div class="bot">
        <p>版权声明 © 2017 - 2023 浙江云端时代科技有限公司 版权所有 </p>
        <p><a href="https://beian.miit.gov.cn/">公示信息：浙ICP备18034741号</a> </p>
      </div>
    </div>
  </div>
</template>
<script  setup >
var width = document.querySelector("html").offsetWidth;

const test = () => {
  var rightcli = document.getElementById("imgcli")
  var tuozhuan = document.querySelector(".tiaozhuan")
  var right1 = document.querySelector(".right1")
  if (width <= 960) {
    rightcli.style.display = "none"
    tuozhuan.style.opacity = 1
    right1.style.display = "block"
    tuozhuan.style.transition = " all 0.4s ease-in-out"
  }


}
const test1 = () => {
  var rightcli = document.getElementById("imgcli")
  var tuozhuan = document.querySelector(".tiaozhuan")
  var right1 = document.querySelector(".right1")
  if (width <= 960) {
    tuozhuan.style.opacity = 0
    // transition: all 0.25s ease-in-out;
    tuozhuan.style.transition = " all 0.4s ease-in-out"
    right1.style.display = "none"
    rightcli.style.display = "block"
  }

}
const ondianji = (e) => {
  var tuozhuan = document.querySelector(".tiaozhuan");
  var right1 = document.querySelector(".right1")
  var rightcli = document.getElementById("imgcli")
  var li = document.querySelectorAll(".li1")



  if (width <= 960) {
    tuozhuan.style.opacity = 0
    right1.style.display = "none"
    rightcli.style.display = "block"
  } else {

    for (let i = 0; i < li.length; i++) {
      const element = li[i];

      element.children[0].style.color = "#000"
    }
    e.target.style.color = "#254461"


  }

}





</script>
<style lang="scss"  >
* {
  user-select: none;
}

body {
  margin: 0;
}

@media screen and (min-width: 960px) {
  .top {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1400px;
    margin: 0 auto;

    ul {
      display: flex;

      li {
        list-style: none;
        margin-right: 80px;

        a {
          text-decoration: none;
          outline: none;
          color: #000;
          font-size: 20px;
          font-weight: 700;

        }

        a:hover {
          // border-bottom: 2px solid salmon;
          color: #254461;
        }
      }

    }

    .left {
      display: flex;

      .logo {
        font-size: 30px;
        color: #254461;
        font-weight: 600;
        margin-top: 6px;
      }

      #right {
        display: none;
      }

      p {
        margin-top: 8px;
        font-size: 14px;
      }
    }



  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .bottom {
    background: url(./img/bottom.jpg);
    height: 348px;

    .center {
      width: 1700px;
      margin: 0 auto;
      text-align: center;


      .top {
        height: 270px;
        display: flex;

        .lef {
          color: #fff;
          list-style: none;
          height: 200px;

          .one {
            .logo {
              font-size: 28px;
              font-weight: 600;
            }

            p {
              margin-top: 8px;
              font-size: 13px;
            }
          }

          .two {
            margin-top: 30px;
            font-size: 22px;
            margin-bottom: 6px;
            text-align: left;
          }

          .three {
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 20px;
            cursor: pointer;






            .three1 {
              width: 40px;
              border: 1px solid #fff;
              border-radius: 50%;
              padding: 6px;
              width: 26px;
              margin-left: 18px;



            }

            .three1:hover {
              background-color: #0a9d4f;
            }




            .four {
              width: 90px;
              height: auto;
              margin-bottom: 6px;
              opacity: 0;

            }

          }

          .three:hover .four {

            opacity: 1;
          }


        }

        .rig {
          p:nth-child(1) {
            font-size: 30px;
            color: #fff;
            margin-bottom: 54px;
          }

          p:not(:first-child) {
            margin-bottom: 36px;
            color: #fff;
          }


        }

      }

      .bot {
        p {
          color: #fff;

          a {
            color: #fff;
            text-decoration: none;
          }
        }

        height: 18px;

        p:nth-child(1) {
          margin-top: 10px;
        }

        p:nth-child(2) {
          margin-top: 24px;

        }
      }
    }

  }
}

@media screen and (max-width: 960px) {


  .top {
    position: relative;

    .left {
      #logo {
        display: none;
      }

      p {
        display: none;
      }

      .logo {
        color: #254461;
        font-weight: 700;
        letter-spacing: 4px;
        padding: 18px 46px;
        border-bottom: 1px solid #e5e1e1;
      }

      #right {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 52px;
        height: 52px;

        img {
          width: 34px;
          margin-top: 10px;
        }
      }
    }

    .tiaozhuan {
      background-color: rgba(50, 58, 67, 1);
      opacity: 0;
      position: absolute;
      top: 52px;
      width: 100%;

      li {
        text-align: center;
        // height: 50px;
        width: 100%;
        font-size: 16px;
        line-height: 50px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        padding: 0 10px;
        text-transform: capitalize;

        a {
          width: 260px;
          color: #fff;
          line-height: 50px;
          text-align: left;
          font-size: 16px;
          text-decoration: none;
          letter-spacing: 2px;
        }


      }
    }


  }

  .bottom {
    height: 80px;
    background-color: #254461;
    position: relative;

    .lef {
      display: none;
    }

    .rig {
      display: none;
    }

    .bot {
      position: absolute;
      bottom: 0;
      margin: 0 10px;

      p {
        text-align: center;
        color: #fff;
        font-size: 12px;
        margin-bottom: 10px;
      }

      a {
        text-decoration: none;
        color: #fff;
      }
    }
  }

}


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}


* {
  font-family: "SourceHanSans";
}
</style>
